<template>
  <div class="text-white">
    <b-row class="justify-content-md-center" v-if="showLoading">
      <b-col cols="12" md="auto"><b-spinner></b-spinner></b-col>
    </b-row>
    <div class="mb-10" v-if="!showLoading && false">
      <b-card class="text-dark">
        <b-media>
          <template v-slot:aside>
            <b-img width="64" alt="placeholder" :src="imagePath(presetData.photo_url)" class="rounded-0" style="height: 100px; width: auto"></b-img>
          </template>

          <h1 class="mt-10 mb-6">{{ presetData.preset_texts.name | uppercase }}</h1>
        </b-media>

        <hr />
        <b-media>
          <div class="lead text-justify" v-html="presetData.preset_texts.description"></div>
        </b-media>
      </b-card>
    </div>
    <div
      v-if="!showLoading"
      class="card card-custom bgi-no-repeat bgi-size-cover gutter-b card-stretch b"
      :style="{
        backgroundImage: `url(${backgroundImage('media/headers/' + presetData.codename + '.jpg')})`,
      }"
    >
      <!--begin::Body-->
      <div class="card-body" style="">
        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm d-flex flex-column align-items-end justify-content-end">
            <b-media class="w-100">
              <template v-slot:aside>
                <b-img width="64" alt="placeholder" :src="imagePath(presetData.photo_url)" class="rounded-0" style="height: 100px; width: auto"></b-img>
              </template>

              <h1 class="mt-10 mb-6 p-2 text-right" style="">{{ presetData.preset_texts.name | uppercase }}</h1>
            </b-media>
            <hr class="border border-white w-100" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm d-flex flex-column align-items-end justify-content-end" style="min-height: 12em">
            <div class="lead text-justify font-weight-bold" v-html="presetData.preset_texts.description" v-if="false"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <transition name="fade-in-up">
      <component
        v-if="!showLoading && saveContent.progress !== 100"
        v-bind:is="engine"
        @save-data="saveData"
        :preset="presetData"
        :saveContent="saveContent"
        :currentToken="''"
      ></component>
    </transition>

    <transition name="fade-in-up">
      <div v-if="saveContent.progress === 100 && !showLoading">
        <b-card>
          <b-card-body>
            <b-row cols="2" col-sm="1" class="d-flex">
              <b-col>
                <h2 class="text-center mb-10 mt-5 text-dark">
                  {{ $t("GENERAL.CONGRATULATIONS") }}
                </h2>
                <p class="lead text-dark text-justify">
                  {{ presetData.texts.invite }}
                </p>
              </b-col>
              <b-col>
                <auth-forms></auth-forms>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </transition>
    <b-toast
      id="my-toast"
      variant="success"
      toaster="b-toaster-top-center"
      auto-hide-delay="1000"
      body-class="hidden"
      header-class="font-weight-bolder text-dark"
    >
      <template #toast-title>
        <i class="fa fa-save mr-4 text-success"></i>
        {{ $t("GENERAL.SAVING_DATA") }}
      </template>
    </b-toast>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Big5Engine from "@/view/pages/content-engines/Big5Engine/Big5Engine";
import GripEngine from "@/view/pages/content-engines/GripEngine/GripEngine";
import VimeoEngine from "@/view/pages/content-engines/VimeoEngine/VimeoEngine";
import MixedCourseEngine from "@/view/pages/content-engines/MixedCourseEngine/MixedCourseEngine";
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";
import { GET_PRESET_DATA_FREE_CONTENT, GET_SAVE_DATA_FREE_CONTENT, UPDATE_SAVE_DATA_FREE_CONTENT } from "@/core/services/store/free-content.module";
import AuthSigninForm from "@/view/pages/auth/components/AuthSigninForm/AuthSigninForm";
import AuthSignupForm from "@/view/pages/auth/components/AuthSignupForm/AuthSignupForm";
import AuthForgotForm from "@/view/pages/auth/components/AuthForgotForm/AuthForgotForm";
import AuthForms from "@/view/pages/auth/components/AuthForms/AuthForms";
import LAUtils from "@/core/services/LAUtils.service";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "run-free-content",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    AuthForms,
    AuthForgotForm,
    AuthSignupForm,
    AuthSigninForm,
    RunContentLastPage,
    Big5Engine,
    VimeoEngine,
    GripEngine,
    MixedCourseEngine,
  },
  // components: {
  //   "big5-engine": () => import("../content-engines/Big5Engine/Big5Engine"),
  // },
  data() {
    return {
      showLoading: true,
      codename: "",
      presetData: {},
      saveContent: {},
      mode: "",
      type: "",
      saveFn: null,
    };
  },
  mixins: [uppercase],
  methods: {
    imagePath(path) {
      return LAUtils.contentImagePath(path);
    },
    updateData() {},
    backgroundImage(path) {
      return process.env.BASE_URL + path;
    },
    saveData() {
      let self = this;
      if (this.saveContent.progress === 100) {
        this.showLoading = true;
        setTimeout(() => {
          self.showLoading = false;
        }, 1000);
      }
      this.$store
        .dispatch("freeContent/" + UPDATE_SAVE_DATA_FREE_CONTENT, {
          codename: this.codename,
          saveContent: this.saveContent,
        })
        .then(() => {
          // TheToaster.saving();
          this.$bvToast.show("my-toast");
        });
    },
  },
  computed: {
    engine() {
      return this.presetData.engine + "-engine";
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, []);
    let self = this;
    this.codename = this.$route.params.codename;

    this.$store
      .dispatch("freeContent/" + GET_PRESET_DATA_FREE_CONTENT, this.codename)
      .then((response) => {
        self.presetData = response.data;
        this.$store
          .dispatch("freeContent/" + GET_SAVE_DATA_FREE_CONTENT, this.codename)
          .then((response) => {
            self.saveContent = response.data;

            self.showLoading = false;
          })
          .catch(() => {
            this.$router.push({ name: "auth-signin" });
          });
      })
      .catch(() => {
        this.$router.push({ name: "auth-signin" });
      });

    // this.updateData();
  },
  beforeDestroy() {},
};
</script>

<style scoped></style>
